
export default {
  props: {
    slice: { type: Object, required: true },
  },
  data() {
    return {
      isViewingDetails: false,
    }
  },
  computed: {
    shouldShowViewButton() {
      return this.slice.items[0]?.raw_html?.includes('View full details')
    },
  },
  mounted() {
    this.fetchScripts()
  },
  methods: {
    fetchScripts() {
      if (process.browser) {
        this.slice.items.forEach((item) => {
          if (item?.raw_html?.includes('<script')) {
            const url = String(item.raw_html).match(/<script .*?src="(.*?)"/)[1]
            const script = window.document.createElement('script')

            script.setAttribute('src', url)
            document.head.appendChild(script)
          }
        })
      }
    },
  },
}
